import { SearchResultGroup } from '../search-result.model';

export interface SearchState {
    resultGroups?: SearchResultGroup[];
    resultGroup?: SearchResultGroup;
    searchTerms: string[];
}
export const initialSearchState: SearchState = {
    resultGroups: [],
    searchTerms: [],
};
